import React, { useEffect } from 'react';
import {
  Box,
  Flex,
  Heading,
  Link,
  Spacer,
  useColorMode,
  IconButton,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  VStack,
  useDisclosure,
  useBreakpointValue,
  Image
} from '@chakra-ui/react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { MoonIcon, SunIcon, HamburgerIcon } from '@chakra-ui/icons';
import { useCurrentColor } from '../contexts/ColorContext';
import { useAuth } from '../contexts/AuthContext';
import { getLogoNoAuth } from 'apis/api';

interface NavLinkProps {
  to: string;
  children: React.ReactNode;
  onClick?: () => void;
}

const Header: React.FC = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const { primaryColor, secondaryColor, textColor } = useCurrentColor();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const navigate = useNavigate();
  const location = useLocation();
  const { isSignedIn, signOut, checkAuthStatus } = useAuth();

  const [logoImageUrl, setLogoImageUrl] = React.useState<string | null>(null);
  const [logoRedering, setLogoRendering] = React.useState<boolean>(false);

  useEffect(() => {
    const fetchLogoImageUrl = async () => {
      const data = await getLogoNoAuth();
      setLogoImageUrl(data.logo_path);
    };
    fetchLogoImageUrl();
  }, [])

  useEffect(() => {
    if (logoImageUrl === null) {
      setLogoRendering(false);
    }
    else if (logoImageUrl === "") {
      setLogoRendering(false);
    }
    else {
      setLogoRendering(true);
    }
  }, [logoImageUrl]);

  useEffect(() => {
    checkAuthStatus();
  }, [location]);

  const NavLink: React.FC<NavLinkProps> = ({ to, children, onClick }) => (
    <Link
      as={RouterLink}
      to={to}
      color={textColor}
      onClick={onClick || (isMobile ? onClose : undefined)}
    >
      {children}
    </Link>
  );

  const handleSignOut = () => {
    signOut();
    navigate('/');
  };

  const renderAuthLinks = () => {
    if (isSignedIn) {
      return (
        <>
          <NavLink to="/admin">Admin</NavLink>
          <Link onClick={handleSignOut} color={textColor}>Sign Out</Link>
        </>
      );
    } else {
      return <NavLink to="/signin">Sign In</NavLink>;
    }
  };

  return (
    <Box
      bg={primaryColor}
      color={textColor}
      py={4}
      position="sticky"
      top={0}
      zIndex={10}
      opacity={0.95}
    >
      <Flex maxW="container.xl" mx="auto" alignItems="center" px={4}>
        {logoRedering ? (
          <Link as={RouterLink} to="/">
            <Image src={logoImageUrl || undefined} alt="GGI Logo" boxSize="50" objectFit='contain'/>
          </Link>
        ) : (
          <Link as={RouterLink} to="/">
            <Heading as="h1" size="lg">GGI (지지아이)</Heading>
          </Link>
        )}
        <Spacer />
        {isMobile ? (
          <>
            <IconButton
              aria-label="Toggle color mode"
              icon={colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
              onClick={() => {
                toggleColorMode();
                onClose();
              }}
              variant="ghost"
              alignSelf="flex-start"
            />
            <IconButton
              aria-label="Open menu"
              icon={<HamburgerIcon />}
              onClick={onOpen}
              variant="ghost"
              color={textColor}
            />
          </>
        ) : (
          <Flex as="nav" gap={4} alignItems="center">
            <NavLink to="/">Home</NavLink>
            <NavLink to="/about">About</NavLink>
            <NavLink to="/products">Products</NavLink>
            <NavLink to="/news">News</NavLink>
            <NavLink to="/contact">Contact</NavLink>
            <NavLink to="/help">Help</NavLink>
            {renderAuthLinks()}
            <IconButton
              aria-label="Toggle color mode"
              icon={colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
              onClick={toggleColorMode}
              variant="ghost"
              color={textColor}
            />
          </Flex>
        )}
      </Flex>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Menu</DrawerHeader>
          <DrawerBody>
            <VStack spacing={4} align="stretch">
              <NavLink to="/">Home</NavLink>
              <NavLink to="/about">About</NavLink>
              <NavLink to="/products">Products</NavLink>
              <NavLink to="/news">News</NavLink>
              <NavLink to="/contact">Contact</NavLink>
              <NavLink to="/help">Help</NavLink>
              {renderAuthLinks()}
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default Header;