import React, { createContext, useState, useContext, useEffect, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom'; // useNavigate 훅을 추가
import { getRefresh, signIn } from '../apis/api';

interface AuthContextType {
  isSignedIn: boolean;
  setIsSignedIn: React.Dispatch<React.SetStateAction<boolean>>;
  signOut: () => void;
  signInUser: (username: string, password: string) => Promise<any>;
  refreshToken: () => Promise<void>;
  checkAuthStatus: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const navigate = useNavigate(); // useNavigate 훅 사용

  const [isSignedIn, setIsSignedIn] = useState<boolean>(
    !!localStorage.getItem('accessToken')
  );

  console.log('isSignedIn:', isSignedIn);
  
  const checkAuthStatus = () => {
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');
    const isAuth = !!accessToken && !!refreshToken;
    setIsSignedIn(isAuth);
    return isAuth;
  };

  useEffect(() => {
    checkAuthStatus();
  }, []);

  useEffect(() => {
    if (!isSignedIn) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('session-exp');
      localStorage.removeItem('access-token-exp');
      localStorage.removeItem('refresh-token-exp');
    }
  }, [isSignedIn]);

  const signOut = () => {
    setIsSignedIn(false);
    localStorage.clear();
    navigate('/signin');  // 로그아웃 시 즉시 /signin으로 리다이렉트
  };

  const signInUser = async (username: string, password: string) => {
    try {
      const data = await signIn(username, password);
      if (data.status === 'success') {
        setIsSignedIn(true);
        localStorage.setItem('session-exp', (Date.now() + 60 * 60 * 1000).toString()); // 1 hour
        localStorage.setItem('access-token-exp', (Date.now() + 60 * 60 * 1000).toString()); // 15 minutes
        localStorage.setItem('refresh-token-exp', (Date.now() + 60 * 60 * 24 * 1000).toString()); // 24 hours
        return data;
      } else {
        throw new Error(data.message || 'Sign in failed');
      }
    } catch (error) {
      console.error('Sign in error:', error);
      throw error;
    }
  };

  const refreshToken = async () => {
    const sessionExp = Number(localStorage.getItem('session-exp'));
    const accessTokenExp = Number(localStorage.getItem('access-token-exp'));
    const refreshTokenExp = Number(localStorage.getItem('refresh-token-exp'));

    if (sessionExp) {
      localStorage.setItem('session-exp', (Date.now() + 60 * 60 * 1000).toString()); // 1 hour
    }

    // 1. 만약 세션이 만료되었으면 로그아웃
    if (sessionExp && (sessionExp < Date.now())) {
      signOut();
      return;
    
    // 2. 만약 리프레시 토큰이 만료되었으면 로그아웃
    } else if (refreshTokenExp && refreshTokenExp < Date.now()) {
      signOut();
      return;
    } 

    // 3. 만약 액세스 토큰이 만료되지 않았으면 리턴
    if (accessTokenExp && accessTokenExp > Date.now()) {
      return;
    }

    //
    try {
      const data = await getRefresh();
      if (data.status === "success") {
        setIsSignedIn(true);
        localStorage.setItem('session-exp', (Date.now() + 60 * 60 * 1000).toString()); // 1 hour
        localStorage.setItem('access-token-exp', (Date.now() + 60 * 60 * 1000).toString()); // 15 minutes
        localStorage.setItem('refresh-token-exp', (Date.now() + 60 * 60 * 24 * 1000).toString()); // 24 hours
      } else {
        throw new Error('Token refresh failed');
      }
    } catch (error) {
      console.error('Token refresh error:', error);
      setIsSignedIn(false);
      throw error;
    }
  };

  return (
    <AuthContext.Provider value={{ isSignedIn, setIsSignedIn, signOut, signInUser, refreshToken, checkAuthStatus }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};