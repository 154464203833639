import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Box, Heading, Tabs, TabList, TabPanels, Tab, TabPanel,
  VStack, Button, Table, Thead, Tbody, Tr, Th, Td, IconButton,
  useToast, useDisclosure, Image, Text, Input, HStack,
  Divider
} from '@chakra-ui/react';
import { AddIcon, EditIcon, DeleteIcon, ViewIcon } from '@chakra-ui/icons';
import { 
  getFAQs, getNews, getContacts, getProducts,
  createFAQ, createNewsItem, createProductItem,
  updateFAQ, updateNewsItem, updateProductItem,
  deleteFAQ, deleteNewsItem, deleteProductItem, deleteContacts,
  postLogo
 } from '../apis/api';
import ModalForm from '../components/ModalForm'; // 새로 만들 컴포넌트
import { useAuth } from '../contexts/AuthContext'
import { IoIosSend } from "react-icons/io";

interface Product {
  id: number;
  name: string;
  price: number;
  description: string;
  features: string[];
  photo_path: string;
}

interface ProductCreate {
  id: number;
  name: string;
  description: string;
  features: string[];
  price: number;
  photo: File | null;
}

interface FAQItem {
  id: number;
  question: string;
  answer: string;
}

interface NewsItem {
  id: number;
  title: string;
  date: string;
  content: string;
}

interface ContactInfo {
  id: number;
  name: string;
  email: string;
  subject: string;
  message: string;
}

interface FileItem {
  id: Date;
  file: File | null;
}

const AdminPage: React.FC = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [faqItems, setFAQItems] = useState<FAQItem[]>([]);
  const [newsItems, setNewsItems] = useState<NewsItem[]>([]);
  const [contacts, setContacts] = useState<ContactInfo[]>([]);

  const [logoItem, setLogoItem] = useState<FileItem | null >(null);
  const logoFileInputRef = useRef<HTMLInputElement>(null);
  
  const [modalType, setModalType] = useState<'product' | 'faq' | 'news' |'contacts'| null>('product');
  const [currentItem, setCurrentItem] = useState<Product | FAQItem | NewsItem | ContactInfo |null>(null);

  const [willRepresentImageStillUse, setWillRepresentImageStillUse] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const { refreshToken } = useAuth();

  const handleRepresentImageCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setWillRepresentImageStillUse(e.target.checked); // 체크 상태를 업데이트
  };

  const fetchContacts = useCallback(async () => {

    await refreshToken();
    
    try {
      const fetchedContacts = await getContacts();
      setContacts(fetchedContacts);
    } catch (error) {
      console.error('Error fetching contacts:', error);
      toast({
        title: "Error fetching contacts",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [toast]);

  useEffect(() => {
    fetchProducts();
    fetchFAQs();
    fetchNews();
    fetchContacts();
  }, [fetchContacts]);

  const fetchProducts = async() => {

    await refreshToken();

    const data = await getProducts();
    if (data === Error) {
      console.error('Error fetching FAQs');
      toast({
        title: "Error fetching FAQs",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setProducts([]);
    } else {
      setProducts(data);
    }
  };

  // console.log(products)

  const fetchFAQs = async () => {

    await refreshToken();

    const data = await getFAQs();
    if (data === Error) {
      console.error('Error fetching FAQs');
      toast({
        title: "Error fetching FAQs",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setFAQItems([]);
    } else {
      setFAQItems(data);
    }

  };

  const fetchNews = async() => {

    await refreshToken();

    const data = await getNews();
    // console.log(data);
    if (data.length === 0) {
      console.error('Error fetching News');
      toast({
        title: "Error fetching News",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setNewsItems([]);
    } else {
      setNewsItems(data);
    }
  };

  const handleOpenModal = (type: 'product' | 'faq' | 'news' | 'contacts', item: Product | FAQItem | NewsItem | ContactInfo |null = null) => {

    if (item === null && type !== 'contacts') {
      let items = createEmptyItem(type);
      setCurrentItem(items);
    } else {
      setCurrentItem(item);
    }
    onOpen();
  };

  const createEmptyItem = (type: 'product' | 'faq' | 'news') => {
    switch (type) {
      case 'product':
        return { id: 0, name: '', price: 0, description: '', features: [''], photo_path: '' };
      case 'faq':
        return { id: 0, question: '', answer: '' };
      case 'news':
        return { id: 0, title: '', date: '', content: '' };
    }
  };

  const handleSaveItem = async (item: Product | ProductCreate | FAQItem | NewsItem) => {

    await refreshToken();

    try {
      if (modalType === 'product') {
        if(item.id !== 0) {
          let updateFormData = new FormData();
          updateFormData.append('id', item.id.toString());
          updateFormData.append('name', (item as ProductCreate).name);
          updateFormData.append('description', (item as ProductCreate).description);
          updateFormData.append('features', JSON.stringify((item as ProductCreate).features));
          updateFormData.append('price', (item as ProductCreate).price.toString());
          if ((item as ProductCreate).photo) {
            updateFormData.append('photo', (item as ProductCreate).photo as File);
        }
          // updateFormData.forEach((value, key) => {
          //   console.log(`${key}: ${value}`);
          // });
          const response = await updateProductItem(updateFormData);
          
          if (response.status === '500' && response.message === "File not found") {
            toast({
              title: "사진을 찾을 수 없습니다. 업데이트를 위해서 사진을 다시 업로드해주세요.",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
            return;
          }
          
          setProducts(prev => prev.map(f => f.id === item.id ? item as Product : f));
        } else {
          
          console.log((item as ProductCreate).photo);

          if ((item as ProductCreate).photo === undefined) {
            toast({
              title: "사진을 업로드해주세요.",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
            return;
          }

          let newFormData = new FormData();
          newFormData.append('name', (item as ProductCreate).name);
          newFormData.append('description', (item as ProductCreate).description);
          newFormData.append('features', JSON.stringify((item as ProductCreate).features));
          newFormData.append('price', (item as ProductCreate).price.toString());
          newFormData.append('photo', (item as ProductCreate).photo as File);
          const addedItem = await createProductItem(newFormData);
          setProducts(prev => [...prev, addedItem]);
        }
        // setProducts(prev => 
        //   item.id ? prev.map(p => p.id === item.id ? item as ProductCreate : p) : [...prev, { ...(item as ProductCreate), id: Date.now() }]
        // );
      } else if (modalType === 'faq') {
        if (item.id) {
          await updateFAQ(item as FAQItem);
          setFAQItems(prev => prev.map(f => f.id === item.id ? item as FAQItem : f));
        } else {
          const addedItem = await createFAQ(
            (item as FAQItem).question, 
            (item as FAQItem).answer
          );
          setFAQItems(prev => [...prev, addedItem]);
        }
      } else if (modalType === 'news') {
        if (item.id) {
          await updateNewsItem(item as NewsItem);
          setNewsItems(prev => prev.map(n => n.id === item.id ? item as NewsItem : n));
        } else {
          const addedItem = await createNewsItem((item as NewsItem).title, (item as NewsItem).date, (item as NewsItem).content);
          setNewsItems(prev => [...prev, addedItem]);
        }
      }
      onClose();
      toast({
        title: `${modalType} item ${item.id ? 'updated' : 'added'}.`,
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      console.error(`Error saving ${modalType} item:`, error);
      toast({
        title: `Error saving ${modalType} item`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDeleteItem = async (id: number, type: 'product' | 'faq' | 'news' | 'contacts') => {

    await refreshToken();

    try {
      if (type === 'product') {
        await deleteProductItem(id);
        setProducts(products.filter(product => product.id !== id));
      } else if (type === 'faq') {
        await deleteFAQ(id);
        setFAQItems(faqItems.filter(faq => faq.id !== id));
      } else if (type === 'news') {
        await deleteNewsItem(id);
        setNewsItems(newsItems.filter(news => news.id !== id));
      } else if (type === 'contacts') {
        await deleteContacts(id);
        setContacts(contacts.filter(contact => contact.id !== id));
      }

      toast({
        title: `${type} item deleted.`,
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      console.error(`Error deleting ${type} item:`, error);
      toast({
        title: `Error deleting ${type} item`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const setLogoImageToState = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files? e.target.files[0] : null;
    const fileId = new Date();

    if (file === null) {
      toast({
        title: "파일을 찾을 수 없습니다.",
        status: "error",
        duration: 3000,
        isClosable: true,
      })
      return;
    }

    const fileItemInstance = {
      id: fileId,
      file: file
    }
    
    setLogoItem(fileItemInstance || null);
  };

  const handleLogoImageUpload = async () => {
      const logoData = new FormData();
      logoData.append('logo_image', logoItem?.file as File);
      const updatedLogo = await postLogo(logoData);

      if (updatedLogo.status === '500' && updatedLogo.message === "File not found") {
        toast({
          title: "로고 이미지를 찾을 수 없습니다. 업데이트를 위해서 로고 이미지를 다시 업로드해주세요.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      } else {
        toast({
          title: "로고 이미지가 업데이트 되었습니다.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setLogoItem(null);
        window.location.reload();
      }

  }
  
  const ProductSection = () => (
    <VStack spacing={4} align="stretch">
      <Heading size="md">Product Management</Heading>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Description</Th>
            <Th>Features</Th>
            <Th>Photo</Th>
          </Tr>
        </Thead>
        <Tbody>
          {products?.map((product) => (
            <Tr key={product.id}>
              <Td>{product.name}</Td>
              <Td>{product.description}</Td>
              <Td>
                {product.features?.map((feature, index)=>{
                  let featureText = '';
                  featureText += feature + ', ';
                  return featureText;
                })}
              </Td>
              <Td>
                <Image src={product.photo_path}/>        
              </Td>
              <Td>
                <IconButton aria-label="Edit product" icon={<EditIcon />} onClick={() => handleOpenModal('product', product)} mr={2} />
                <IconButton aria-label="Delete product" icon={<DeleteIcon />} onClick={() => handleDeleteItem(product.id, 'product')} />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Button leftIcon={<AddIcon />} colorScheme="blue" onClick={() => handleOpenModal('product')}>
        Add New Product
      </Button>
    </VStack>
  );

  const FAQSection = () => (
    <VStack spacing={4} align="stretch">
      <Heading size="md">FAQ Management</Heading>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Question</Th>
            <Th>Answer</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {faqItems.map((faq) => (
            <Tr key={faq.id}>
              <Td>{faq.question}</Td>
              <Td>{faq.answer}</Td>
              <Td>
                <IconButton aria-label="Edit FAQ" icon={<EditIcon />} onClick={() => handleOpenModal('faq', faq)} mr={2} />
                <IconButton aria-label="Delete FAQ" icon={<DeleteIcon />} onClick={() => handleDeleteItem(faq.id, 'faq')} />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Button leftIcon={<AddIcon />} colorScheme="blue" onClick={() => handleOpenModal('faq')}>
        Add New FAQ
      </Button>
    </VStack>
  );

  const NewsSection = () => (
    <VStack spacing={4} align="stretch">
      <Heading size="md">News Management</Heading>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Title</Th>
            <Th>Date</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {newsItems.map((news) => (
            <Tr key={news.id}>
              <Td>{news.title}</Td>
              <Td>{news.date}</Td>
              <Td>
                <IconButton aria-label="Edit news" icon={<EditIcon />} onClick={() => handleOpenModal('news', news)} mr={2} />
                <IconButton aria-label="Delete news" icon={<DeleteIcon />} onClick={() => handleDeleteItem(news.id, 'news')} />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Button leftIcon={<AddIcon />} colorScheme="blue" onClick={() => handleOpenModal('news')}>
        Add New News Item
      </Button>
    </VStack>
  );

  const SettingSection = () => (
    <VStack spacing={4} align="stretch">
      <Heading size="md">Settings</Heading>

      <Text>1. 로고 이미지 변경하기 (로고의 크기는 가로가 길게, 세로가 짧게 업로드해주세요.)</Text>
      <Box>
        <VStack>
          <Button 
            colorScheme="blue" 
            onClick={() => logoFileInputRef.current?.click()}
            overflow='hidden'
          >
            {logoItem?.file?.name || "로고를 선택해주세요."}
          </Button>
          <Input 
            name="logo" 
            type='file' 
            accept=".png, .jpg, .jpeg, .webp" 
            onChange={setLogoImageToState}
            ref={logoFileInputRef}
            hidden
          />
          {logoItem && (
            <HStack>
            <Button 
              colorScheme="blue" 
              onClick={handleLogoImageUpload} 
              leftIcon={<IoIosSend/>}
            >
              Upload
            </Button>
            <Button
              colorScheme="red"
              onClick={() => setLogoItem(null)}
            >
              Cancel
            </Button>
            </HStack>
          )}
        </VStack>
      </Box>
      
      
      <Divider />
      
    </VStack>
  )

  const ContactSection = () => (
    <VStack spacing={4} align="stretch">
      <Heading size="md">Contact Messages</Heading>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Email</Th>
            <Th>Subject</Th>
            <Th>Message</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {contacts.map((contact) => (
            <Tr key={contact.id}>
              <Td>{contact.name}</Td>
              <Td>{contact.email}</Td>
              <Td>{contact.subject}</Td>
              <Td>{contact.message.substring(0, 50)}...</Td>
              <Td>
                <IconButton aria-label="View message" icon={<ViewIcon/>} mr={2} onClick={() => handleOpenModal('contacts', contact)} />
                <IconButton aria-label="Delete message" icon={<DeleteIcon />} onClick={() => handleDeleteItem(contact.id, 'contacts')} />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </VStack>
  );

  return (
    <Box maxW="container.xl" mx="auto" mt={8} p={4}>
      <Heading as="h1" size="2xl" mb={6}>Admin Dashboard</Heading>
      <Tabs>
        <TabList>
          <Tab onClick={()=>{
            setModalType('product');
          }}>
            Products
          </Tab>
          <Tab onClick={()=>{
            setModalType('faq');
          }}>
            FAQ
          </Tab>
          <Tab onClick={()=>{
            setModalType('news');
          }}>
            News
          </Tab>
          <Tab onClick={()=>{
            setModalType('contacts');
          }}>
            Contacts
          </Tab>
          <Tab>
            Settings
          </Tab>
        </TabList>

        <TabPanels overflow='auto' h='70vh'>
          <TabPanel>
            <ProductSection />
          </TabPanel>
          <TabPanel>
            <FAQSection />
          </TabPanel>
          <TabPanel>
            <NewsSection />
          </TabPanel>
          <TabPanel>
            <ContactSection />
          </TabPanel>
          <TabPanel>
            <SettingSection />
          </TabPanel>
        </TabPanels>
      </Tabs>

      <ModalForm 
        isOpen={isOpen}
        onClose={onClose}
        modalType={modalType}
        currentItem={currentItem}
        onSave={handleSaveItem}
      />
    </Box>
  );
};

export default AdminPage;