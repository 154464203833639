import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Heading,
  Image,
  Text,
  Button,
  VStack,
  HStack,
  Grid,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerFooter,
  DrawerBody,
  DrawerCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  NumberInput,
  NumberInputField,
  IconButton,
  Flex,
  Spacer,
  Center,
  Tooltip,
  Badge,
  Icon,
  Checkbox,
} from '@chakra-ui/react';
import { EditIcon, AddIcon, DeleteIcon } from '@chakra-ui/icons';
import { useToast } from '@chakra-ui/react';
import { getProductsNoAuth, getProductsDetailNoAuth, postProductsDetail, deleteProductsDetail } from 'apis/api';
import { useAuth } from 'contexts/AuthContext';

interface ProductModel {
  model_name: string;
  model_size: string;
  model_price: number;
}

interface ProductDetail {
  uuid: string | null;
  product_id: number;
  detail_name: string;
  detail_price: number;
  detail_photo_path: string;
  detail_metadata: Record<string, any>;
  detail_models: ProductModel[];
  detail_photo: File | null;
  detail_description_photo: File | null;
  detail_description_photo_path: string;
}

interface Product {
  id: string;
  name: string;
  description: string;
  features: string[];
  price: number;
  photo_path: string;
}

const ProductDetailPage: React.FC = () => {

    const { isSignedIn, refreshToken } = useAuth();
    const toast = useToast();

    const { productID } = useParams<{ productID: string }>();
    const [product, setProduct] = useState<Product | null>(null);
    const [productDetails, setProductDetails] = useState<ProductDetail[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedDetail, setSelectedDetail] = useState<ProductDetail | null>(null);
    const [isAdmin, setIsAdmin] = useState(isSignedIn);  // 실제 구현에서는 인증 상태에 따라 설정
    const [willRepresentImageStillUse, setWillRepresentImageStillUse] = useState(false);
    const [willDescriptionImageStillUse, setWillDescriptionImageStillUse] = useState(false);

    const { isOpen: isViewOpen, onOpen: onViewOpen, onClose: onViewClose } = useDisclosure();
    const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();
    const detailsPerPage = 9;

    const productDetailRepresentImageRef = useRef<HTMLInputElement>(null);
    const productDetailDescriptionImageRef = useRef<HTMLInputElement>(null);

    const handleRepresentImageCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setWillRepresentImageStillUse(e.target.checked); // 체크 상태를 업데이트
    };


    const handleDescriptionImageCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setWillDescriptionImageStillUse(e.target.checked); // 체크 상태를 업데이트
    };

    useEffect(() => {
        const fetchData = async () => {
            const data = await getProductsDetailNoAuth(productID);
                if (data.length === 0) {
                    console.error('Error fetching Product Detail');
                    setProductDetails([]);
                } else {
                    setProductDetails(data);
                }
        }

        fetchData();
        
    }, [productID]);

    useEffect(() => {
        const fetchData = async () => {
        const data = await getProductsNoAuth(productID);
            if (data.length === 0) {
                console.error('Error fetching Product');
                setProduct(null);
            } else {
                setProduct(data[0]);
            }
        };
        fetchData();
    }, [])

    const makeRandomMetaData = () => {
        const randomHashMap = {
            "색": ["빨강", "파랑", "노랑", "초록", "보라", "주황", "검정", "흰색"],
            "재질": ["나무", "금속", "플라스틱", "유리", "석재", "피혁"],
            "무게": ["가벼운", "중간", "무거운"],
            "형태": ["둥근", "사각", "삼각", "마름모"],
        };
        
        // 랜덤한 키 선택
        const randomKey = Object.keys(randomHashMap)[Math.floor(Math.random() * Object.keys(randomHashMap).length)];
    
        // 해당 키에 대한 랜덤한 값 선택
        const randomValue = randomHashMap[randomKey as keyof typeof randomHashMap][Math.floor(Math.random() * randomHashMap[randomKey as keyof typeof randomHashMap].length)];
        
        // 키-값 쌍 반환
        return { [randomKey]: randomValue };
    }
    

    const handleDetailClick = (detail: ProductDetail) => {
        
        setSelectedDetail(detail);
        onViewOpen();
    };

    const handleEditClick = (event: React.MouseEvent, detail: ProductDetail) => {
        event.stopPropagation();
        
        setSelectedDetail({ ...detail, detail_photo: null });
        console.log(detail);
        onEditOpen();
    };

    const handleSave = async(updatedDetail: ProductDetail) => {

        await refreshToken();

        // 만약 CREATE 모드인데 이미지가 없다면
        if (updatedDetail.uuid === null && updatedDetail.detail_photo === null) {
            toast({
                title: "대표 이미지를 넣어주세요.",
                status: "error",
                duration: 3000,
                isClosable: true,
            })
            return;
        }

        // 만약에 대표이미지를 변경하지 않겠다면
        if (willRepresentImageStillUse) {
            updatedDetail.detail_photo = null;

            // 만약 대표이미지를 변경할건데 이미지가 없다면
        } else if (!willDescriptionImageStillUse && updatedDetail.detail_photo === null) {
            toast({
                title: "대표 이미지를 넣어주세요.",
                status: "error",
                duration: 3000,
                isClosable: true,
            })
            return;
        }

        // 만약에 설명이미지를 변경하지 않겠다면
        if (willDescriptionImageStillUse) {
            updatedDetail.detail_description_photo = null;
        } else if (!willDescriptionImageStillUse && updatedDetail.detail_description_photo === null) {
            toast({
                title: "설명 이미지를 넣어주세요.",
                status: "error",
                duration: 3000,
                isClosable: true,
            })
            return;
        }

        


        const formData = new FormData();
        formData.append('uuid', updatedDetail.uuid || '');
        formData.append('product_id', updatedDetail.product_id.toString());
        formData.append('detail_name', updatedDetail.detail_name);
        formData.append('detail_price', updatedDetail.detail_price.toString());
        formData.append('detail_metadata', JSON.stringify(updatedDetail.detail_metadata));
        formData.append('detail_models', JSON.stringify(updatedDetail.detail_models));
        formData.append('detail_photo', updatedDetail.detail_photo || '');
        formData.append('detail_description_photo', updatedDetail.detail_description_photo || '');

        const data = await postProductsDetail(formData)
        if (data.status !== "fail" ) {
            toast({
                title: "저장되었습니다.",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
            window.location.reload();
        } else {
            toast({
                title: "저장에 실패했습니다.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }


        onEditClose();
    };

    const handleDelete = async(id: string, product_id: number) => {
        // setProductDetails(prevDetails =>
        // prevDetails.filter(detail => detail.id !== id)
        // );
        await refreshToken();        
        await deleteProductsDetail(id, product_id);
        window.location.reload();
        onEditClose();
    };

    const handleCreate = () => {
        const newDetail: ProductDetail = {
        uuid: null,
        product_id: parseInt(productID || '0'),
        detail_name: "새로운 모델",
        detail_price: 0,
        detail_photo_path: "",
        detail_photo: null,
        detail_description_photo: null,
        detail_description_photo_path: "",
        detail_metadata: [
            
        ],
        detail_models: [
            {
                "model_name":"Model 1A",
                "model_size":"대",
                "model_price":120000
            },
        ]
        };
        setSelectedDetail(newDetail);
        onEditOpen();
    };

    const handleDeleteSelectedDetailModel = (modelDetailIndex: number) => {
        if (selectedDetail) {
            // selectedDetail.detail_models 에서 modelDetailIndex 번째 요소 삭제
            return () => setSelectedDetail(prev => prev ? {...prev, detail_models: prev.detail_models.filter((_, index) => index !== modelDetailIndex)} : null);
        }
    };

    const handleAddSelectedDetailModel = () => {
        if (selectedDetail) {
            // selectedDetail.detail_models 에 새 요소 추가
            setSelectedDetail(prev => prev ? {...prev, detail_models: [...prev.detail_models, { model_name: "", model_size: "", model_price: 0 }]} : null);
        }
    }

    const handleAddSelectedDetailMetaData = () => {
        if (selectedDetail) {
            // selectedDetail.detail_metadata 에 새 요소 추가
            setSelectedDetail(prev => prev ? {...prev, detail_metadata: {...prev.detail_metadata, ...makeRandomMetaData()}} : null);
        }
    }

    const indexOfLastDetail = currentPage * detailsPerPage;
    const indexOfFirstDetail = indexOfLastDetail - detailsPerPage;
    const currentDetails = productDetails.slice(indexOfFirstDetail, indexOfLastDetail);

    const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
    console.log(productDetails)

    if (!product) return <Box>Loading...</Box>;

    return (
        <Box maxW="container.xl" mx="auto" mt={8} p={4}>
        <VStack spacing={8} align="stretch">
            {/* 제품 주요 정보 */}
            <HStack spacing={8} align="flex-start">
            <Image
                src={product.photo_path}
                alt={product.name}
                borderRadius="lg"
                maxW="300px"
            />
            <VStack align="flex-start" spacing={4}>
                <Heading as="h1" size="2xl">{product.name}</Heading>
                <Text fontSize="xl" fontWeight="bold">가격: {product.price.toLocaleString()}원</Text>
                <Text>{product.description}</Text>
                <HStack>
                {product.features.map((feature, index) => (
                    <Button key={index} size="sm" variant="outline">
                    {feature}
                    </Button>
                ))}
                </HStack>
            </VStack>
            </HStack>

            {/* 상세 모델 그리드 */}
            <HStack justify="space-between">
            <Heading as="h2" size="lg">상세 모델</Heading>
            {isAdmin && (
                <Button leftIcon={<AddIcon />} colorScheme="green" onClick={handleCreate}>
                새 모델 추가
                </Button>
            )}
            </HStack>
            <Grid templateColumns="repeat(3, 1fr)" gap={6}>
            {currentDetails.map((detail, index) => (
                <Box 
                key={index} 
                borderWidth={1} 
                borderRadius="lg" 
                p={4}
                onClick={() => handleDetailClick(detail)}
                cursor="pointer"
                _hover={{ boxShadow: 'md' }}
                position="relative"
                >
                <Image
                    src={detail.detail_photo_path}
                    alt={detail.detail_name}
                    borderRadius="md"
                    mb={2}
                />
                <Heading size="md" mb={2}>{detail.detail_name}</Heading>
                <Text fontWeight="bold" mb={2}>가격: {detail.detail_price.toLocaleString()}원</Text>
                <Text>모델 수: {detail.detail_models.length}</Text>
                {isAdmin && (
                    <IconButton
                    aria-label="Edit detail"
                    icon={<EditIcon />}
                    size="sm"
                    position="absolute"
                    top={2}
                    right={2}
                    onClick={(e) => handleEditClick(e, detail)}
                    />
                )}
                </Box>
            ))}
            </Grid>

            {/* 페이지네이션 */}
            <HStack justify="center" spacing={2}>
            {Array.from({ length: Math.ceil(productDetails.length / detailsPerPage) }, (_, i) => (
                <Button 
                key={i} 
                onClick={() => paginate(i + 1)}
                colorScheme={currentPage === i + 1 ? "blue" : "gray"}
                >
                {i + 1}
                </Button>
            ))}
            </HStack>
        </VStack>

        {/* 상세 정보 보기 모달 (일반 사용자용) */}
        <Drawer isOpen={isViewOpen} onClose={onViewClose} size="xl">
            <DrawerOverlay />
            <DrawerContent>
                <DrawerHeader>{selectedDetail?.detail_name}</DrawerHeader>
                <DrawerCloseButton />
                <DrawerBody>
                    <VStack spacing={4} align="stretch">
                    <Image
                        src={selectedDetail?.detail_photo_path}
                        alt={selectedDetail?.detail_name}
                        borderRadius="md"
                        maxH="200px"
                        maxW="100%"  // 최대 너비를 부모 요소 너비에 맞춤
                        objectFit="contain"  // 이미지 비율을 유지하면서 콘텐츠가 잘리지 않도록 설정
                    />
                    <Text fontWeight="bold">가격: {selectedDetail?.detail_price.toLocaleString()}원</Text>
                    <HStack>
                        {selectedDetail && Object.entries(selectedDetail.detail_metadata).map(([key, value], index) => (
                            <Badge key={index} colorScheme="green">
                                {key}: {value}
                            </Badge>
                        ))}
                    </HStack>
                    <Text>모델:</Text>
                    {selectedDetail?.detail_models.map((model, index) => (
                        <Box key={index} p={2} borderWidth={1} borderRadius="md">
                            <Text fontWeight="bold">{model.model_name}</Text>
                            <Text>크기: {model.model_size}</Text>
                            <Text>가격: {model.model_price.toLocaleString()}원</Text>
                        </Box>
                    ))}
                    {selectedDetail?.detail_description_photo_path && (
                        <Image
                            src={selectedDetail?.detail_description_photo_path}
                            alt={selectedDetail?.detail_name}
                            borderRadius="md"
                        />
                    )}
                    </VStack>
                </DrawerBody>
                <DrawerFooter>
                    <Button onClick={onViewClose}>닫기</Button>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>

        {/* 상세 정보 편집 모달 (관리자용) */}
        <Drawer isOpen={isEditOpen} onClose={onEditClose} size="xl">
            <DrawerOverlay />
            <DrawerContent>
            <DrawerHeader>{selectedDetail?.uuid === null ? "새 모델 추가" : `${selectedDetail?.detail_name} 편집`}</DrawerHeader>
            <DrawerCloseButton />
            <DrawerBody>
                <VStack spacing={4}>
                <FormControl>
                    <FormLabel>이름</FormLabel>
                    <Input 
                    value={selectedDetail?.detail_name} 
                    onChange={(e) => setSelectedDetail(prev => prev ? {...prev, detail_name: e.target.value} : null)}
                    />
                </FormControl>
                <FormControl>
                    <FormLabel>가격</FormLabel>
                    <NumberInput
                    value={selectedDetail?.detail_price}
                    onChange={(_, value) => setSelectedDetail(prev => prev ? {...prev, detail_price: value} : null)}
                    >
                    <NumberInputField />
                    </NumberInput>
                </FormControl>
                <FormControl>
                    <Flex mb='5'>
                        <Center>
                            <FormLabel>모델</FormLabel>
                        </Center>                        
                        <Spacer />
                        <IconButton
                            aria-label="모델 추가"
                            icon={<AddIcon />}
                            onClick={handleAddSelectedDetailModel}
                            colorScheme='green'
                        />                        
                    </Flex>
                    {selectedDetail?.detail_models.map((model, index) => (
                        <Box 
                            key={index} 
                            p={2} 
                            borderWidth={1} 
                            borderRadius="md" 
                            mb='5'
                            _hover={{ boxShadow: 'md' }}
                        >   
                            <Flex>
                                <Center>모델 이름</Center>
                                <Spacer />
                                <IconButton
                                    aria-label="모델 삭제"
                                    icon={<DeleteIcon />}
                                    onClick={handleDeleteSelectedDetailModel(index)}
                                    colorScheme='red'
                                />    
                            </Flex>                            
                            <Input 
                                value={model.model_name}
                                mt='2'
                                onChange={(e) => setSelectedDetail(prev => prev ? {...prev, detail_models: prev.detail_models.map((m, i) => i === index ? {...m, model_name: e.target.value} : m)} : null)}                            
                            />
                            <Text
                                mt='2'
                            >
                                모델 사이즈
                            </Text>
                            <Input 
                                value={model.model_size} 
                                mt='2'
                                onChange={(e) => setSelectedDetail(prev => prev ? {...prev, detail_models: prev.detail_models.map((m, i) => i === index ? {...m, model_size: e.target.value} : m)} : null)}
                            />
                            <Text                                
                                mt='2'                                
                            >
                                모델 가격
                            </Text>
                            <Input                                
                                value={model.model_price}
                                mt='2'
                                onChange={(e) => {
                                    const value = e.target.value;

                                    // 입력이 빈 문자열일 경우 0으로 처리
                                    const parsedValue = value === "" ? 0 : parseInt(value, 10);

                                    setSelectedDetail(prev => 
                                        prev ? {
                                            ...prev, 
                                            detail_models: prev.detail_models.map((m, i) => 
                                                i === index ? { ...m, model_price: isNaN(parsedValue) ? 0 : parsedValue } : m
                                            )
                                        } : null
                                    );
                                }}
                            />
                        </Box>
                    ))}                    
                </FormControl>
                <FormControl>
                    <Flex>
                        <FormLabel>메타데이터</FormLabel>
                        <Spacer />
                        <IconButton
                            aria-label="모델 추가"
                            icon={<AddIcon />}
                            onClick={handleAddSelectedDetailMetaData}
                            colorScheme='green'
                        />
                    </Flex>
                    <HStack>
                    {selectedDetail && Object.entries(selectedDetail.detail_metadata).map(([key, value], index) => (
                        <Badge key={index} colorScheme="green" mt='2'>
                            {key}: {value}
                        </Badge>
                    ))} 
                    </HStack>                                       
                    {selectedDetail && Object.entries(selectedDetail.detail_metadata).map(([key, value], index) => (
                        <HStack key={index} mt='2'>
                            <Input 
                                value={key}
                                onChange={(e) => setSelectedDetail(prev => prev ? {...prev, detail_metadata: {...Object.fromEntries(Object.entries(prev.detail_metadata).map(([k, v]) => k === key ? [e.target.value, v] : [k, v]))}} : null)}
                            />
                            <Input 
                                value={value}
                                onChange={(e) => setSelectedDetail(prev => prev ? {...prev, detail_metadata: {...Object.fromEntries(Object.entries(prev.detail_metadata).map(([k, v]) => k === key ? [k, e.target.value] : [k, v]))}} : null)}
                            />
                            <IconButton
                                aria-label="모델 삭제"
                                icon={<DeleteIcon />}
                                onClick={() => setSelectedDetail(prev => prev ? {...prev, detail_metadata: {...Object.fromEntries(Object.entries(prev.detail_metadata).filter(([k, _]) => k !== key))}} : null)}
                                colorScheme='red'
                            />
                        </HStack>
                    ))}
                </FormControl>
                <FormControl>
                    <FormLabel>대표 이미지 업로드</FormLabel>
                    <Tooltip label={selectedDetail?.detail_photo?.name || "모델 이미지를 선택해주세요."} fontSize='md'>
                        <Button 
                            colorScheme="blue" 
                            onClick={() => productDetailRepresentImageRef.current?.click()}                        
                            // 나머지 부분은 overflow한 경우엔 eclipse 처리
                            whiteSpace='nowrap'
                            textOverflow='ellipsis'
                            overflow='hidden'
                            maxWidth='200px'
                            display="block"
                        >
                            {selectedDetail?.detail_photo?.name || "모델 이미지를 선택해주세요."}
                        </Button>
                    </Tooltip>                    
                    <Input   
                        hidden                 
                        name="productDetailImage" 
                        type='file' 
                        accept=".png, .jpg, .jpeg, .webp"
                        ref={productDetailRepresentImageRef}                    
                        onChange={(e) => setSelectedDetail(
                            prev => prev ? {...prev, detail_photo: e.target.files ? e.target.files[0] : null} : null)}                                            
                    />                
                </FormControl>
                <FormControl>
                    <FormLabel>기존 대표 이미지 사용 여부</FormLabel>
                    <Checkbox
                        isChecked={willRepresentImageStillUse} // 체크박스 상태를 상태 변수와 연결
                        onChange={handleRepresentImageCheckboxChange} // 체크박스 상태 변경 시 호출될 함수
                    >
                        기존 대표 이미지 사용
                    </Checkbox>
                </FormControl>
                <FormControl>
                <FormLabel>설명 이미지 업로드</FormLabel>
                    <Tooltip label={selectedDetail?.detail_description_photo?.name || "모델 이미지를 선택해주세요."} fontSize='md'>
                        <Button 
                            colorScheme="blue" 
                            onClick={() => productDetailDescriptionImageRef.current?.click()}                        
                            // 나머지 부분은 overflow한 경우엔 eclipse 처리
                            whiteSpace='nowrap'
                            textOverflow='ellipsis'
                            overflow='hidden'
                            maxWidth='200px'
                            display="block"
                        >
                            {selectedDetail?.detail_description_photo?.name || "모델 이미지를 선택해주세요."}
                        </Button>
                    </Tooltip>                    
                    <Input   
                        hidden                 
                        name="productDetailImage" 
                        type='file' 
                        accept=".png, .jpg, .jpeg, .webp"
                        ref={productDetailDescriptionImageRef}                    
                        onChange={(e) => setSelectedDetail(
                            prev => prev ? {...prev, detail_description_photo: e.target.files ? e.target.files[0] : null} : null)}                                            
                    />                
                </FormControl>
                <FormControl>
                    <FormLabel>기존 설명 이미지 사용 여부</FormLabel>
                    <Checkbox
                        isChecked={willDescriptionImageStillUse} // 체크박스 상태를 상태 변수와 연결
                        onChange={handleDescriptionImageCheckboxChange} // 체크박스 상태 변경 시 호출될 함수
                    >
                        기존 설명 이미지 사용
                    </Checkbox> 
                </FormControl>
                </VStack>
            </DrawerBody>
            <DrawerFooter>
                <Button colorScheme="blue" mr={3} onClick={() => selectedDetail && handleSave(selectedDetail)}>
                저장
                </Button>
                {selectedDetail?.uuid && (selectedDetail?.uuid !== null) && (
                    <Button colorScheme="red" onClick={() => selectedDetail?.uuid && handleDelete(selectedDetail.uuid, selectedDetail.product_id)}>삭제</Button>
                )}
            </DrawerFooter>
            </DrawerContent>
        </Drawer>
        </Box>
    );
};

export default ProductDetailPage;