export const getBaseUrl = () => {
  const env = process.env.NODE_ENV
  if(env === 'production') {
      return ''
  } else {
      return 'http://localhost:8000'
  }
}

export const getTokens = (type) => {
if (type === 'refresh') {
  const refreshToken = localStorage.getItem('refreshToken');
  return {'refresh-token': refreshToken};
} else if (type === 'access') {
  const accessToken = localStorage.getItem('accessToken');
  return {'access-token': accessToken};
}
return {};
};

export const isAuthenticated = () => {
  // console.log(getTokens('access'));
  return getTokens('access')["access-token"] !== null;
};

export const getRefresh = async () => {
  const refreshToken = getTokens("refresh");

  const apiUrl = `${getBaseUrl()}/api/auth/refresh`;

  try {
    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${refreshToken['refresh-token']}`
      },
    });
    const data = await response.json();

    localStorage.setItem('accessToken', response.headers.get('access-token') || '');
    localStorage.setItem('refreshToken', response.headers.get('refresh-token') || '');

    return data;
  } catch (error) {
    throw error;
  }
};

export const signIn = async (username, password) => {
  try {
    const response = await fetch(`${getBaseUrl()}/api/auth/sign_in`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user_name: username,
        password: password
      }),
    });

    const data = await response.json();

    if (data.status === 'success') {
      localStorage.setItem('accessToken', response.headers.get('access-token'));
      localStorage.setItem('refreshToken', response.headers.get('refresh-token'));
      return data;
    } else {
      throw new Error(data.message || 'Sign in failed');
    }
  } catch (error) {
    console.error('Sign in error:', error);
    throw error;
  }
};

export const getFAQs = async () => {
  const accessToken = getTokens('access');
  try {
    const response = await fetch(`${getBaseUrl()}/api/admin/faq`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken['access-token']}`
      }
    });
    return await response.json();
  } catch (error) {
    console.error('Error fetching FAQs:', error);
    return [];
  }
};

export const getNews = async () => {
  const accessToken = getTokens('access');
  try {
    const response = await fetch(`${getBaseUrl()}/api/admin/news`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken['access-token']}`
      }
    });
    return await response.json();
  } catch (error) {
    console.error('Error fetching news:', error);
    return [];
  }
};


export const getContacts = async () => {
  const accessToken = getTokens('access');
  try {
    const response = await fetch(`${getBaseUrl()}/api/admin/contacts`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken['access-token']}`
      }
    });
    return await response.json();
  } catch (error) {
    console.error('Error fetching contacts:', error);
    throw error;
  }
};

export const getProducts = async () => {
  const accessToken = getTokens('access');
  try {
    const response = await fetch(`${getBaseUrl()}/api/admin/products`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken['access-token']}`
      }
    });
    return await response.json();
  } catch (error) {
    console.error('Error fetching products:', error);
    return [];
  }
};

export const createFAQ = async (question, answer) => {
const accessToken = getTokens('access');
try {
  const response = await fetch(`${getBaseUrl()}/api/admin/faq`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken['access-token']}`
    },
    body: JSON.stringify({ question, answer })
  });
  return await response.json();
} catch (error) {
  console.error('Error creating FAQ:', error);
  throw error;
}
};

export const updateFAQ = async (faq) => {
const accessToken = getTokens('access');
try {
  const response = await fetch(`${getBaseUrl()}/api/admin/faq?id=${faq.id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken['access-token']}`
    },
    body: JSON.stringify(faq)
  });
  return await response.json();
} catch (error) {
  console.error('Error updating FAQ:', error);
  throw error;
}
};

export const deleteFAQ = async (id) => {
const accessToken = getTokens('access');
try {
  const response = await fetch(`${getBaseUrl()}/api/admin/faq?id=${id}`, {
    method: 'DELETE',
    headers: {
      'Authorization': `Bearer ${accessToken['access-token']}`
    }
  });
  return await response.json();
} catch (error) {
  console.error('Error deleting FAQ:', error);
  throw error;
}
};

export const createNewsItem = async (title, date, content) => {
  const accessToken = getTokens('access');
  
  // date를 ISO 8601 형식의 문자열로 변환
  console.log(date);
  const isoDateTime = new Date(date).toISOString();

  try {
    const response = await fetch(`${getBaseUrl()}/api/admin/news`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken['access-token']}`
      },
      body: JSON.stringify({ 
        title, 
        date: isoDateTime,  // ISO 8601 형식의 날짜와 시간
        content 
      })
    });
    return await response.json();
  } catch (error) {
    console.error('Error creating news item:', error);
    throw error;
  }
};

export const updateNewsItem = async (newsItem) => {
const accessToken = getTokens('access');
try {
  const response = await fetch(`${getBaseUrl()}/api/admin/news?id=${newsItem.id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken['access-token']}`
    },
    body: JSON.stringify(newsItem)
  });
  return await response.json();
} catch (error) {
  console.error('Error updating news item:', error);
  throw error;
}
};

export const deleteNewsItem = async (id) => {
const accessToken = getTokens('access');
try {
  const response = await fetch(`${getBaseUrl()}/api/admin/news?id=${id}`, {
    method: 'DELETE',
    headers: {
      'Authorization': `Bearer ${accessToken['access-token']}`
    }
  });
  return await response.json();
} catch (error) {
  console.error('Error deleting news item:', error);
  throw error;
}
};


export const sendContactMessage = async (formData) => {
  const response = await fetch(`${getBaseUrl()}/api/service/contacts`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...getTokens('access')
    },
    body: JSON.stringify(formData)
  });

  if (!response.ok) {
    throw new Error('Failed to send message');
  }

  return response;
};


export const createProductItem = async (product) => {
  const accessToken = getTokens('access');
  try {
    const response = await fetch(`${getBaseUrl()}/api/admin/products`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${accessToken['access-token']}`
      },
      body: product
    });
    return await response.json();
  } catch (error) {
    console.error('Error creating product item:', error);
    throw error;
  }
};

export const updateProductItem = async (product) => {

  // console.log(product);
  const accessToken = getTokens('access');
  try {
    const response = await fetch(`${getBaseUrl()}/api/admin/products`, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${accessToken['access-token']}`
      },
      body: product
    });
    return await response.json();
  } catch (error) {
    console.error('Error updating product item:', error);
    throw error;
  }
}

export const deleteProductItem = async (id) => {
  const accessToken = getTokens('access');
  try {
    const response = await fetch(`${getBaseUrl()}/api/admin/products?id=${id}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${accessToken['access-token']}`
      }
    });
    return await response.json();
  } catch (error) {
    console.error('Error deleting product item:', error);
    throw error;
  }
}

export const deleteContacts = async (id) => {
  const accessToken = getTokens('access');
  try {
    const response = await fetch(`${getBaseUrl()}/api/admin/contacts?id=${id}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${accessToken['access-token']}`
      }
    });
    return await response.json();
  } catch (error) {
    console.error('Error deleting contact:', error);
    throw error;
  }
}

export const postLogo = async (formData) => {
  const accessToken = getTokens('access');
  try {
    const response = await fetch(`${getBaseUrl()}/api/service/logo`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${accessToken['access-token']}`
      },
      body: formData
    });
    return await response.json();
  } catch (error) {
    console.error('Error posting logo:', error);
    throw error;
  }
}

export const postProductsDetail = async (formData) => {
  const accessToken = getTokens('access');
  try {
    const response = await fetch(`${getBaseUrl()}/api/service/products_detail`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${accessToken['access-token']}`
      },
      body: formData
    });
    return await response.json();
  } catch (error) {
    console.error('Error posting product detail:', error);
    throw error;
  }
}

export const deleteProductsDetail = async (uuid, product_id) => {
  const accessToken = getTokens('access');
  try {
    const response = await fetch(`${getBaseUrl()}/api/service/products_detail?uuid=${uuid}&product_id=${product_id}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${accessToken['access-token']}`
      }
    });
    return await response.json();
  } catch (error) {
    console.error('Error deleting product detail:', error);
    throw error;
  }
}

// No Auth Required //


export const getFAQsNoAuth = async () => {
  // const accessToken = getTokens('access');
  try {
    const response = await fetch(`${getBaseUrl()}/api/service/get/faq`, {
      method: 'GET',
    });
    return await response.json();
  } catch (error) {
    console.error('Error fetching FAQs:', error);
    return [];
  }
};

export const getNewsNoAuth = async () => {
  // const accessToken = getTokens('access');
  try {
    const response = await fetch(`${getBaseUrl()}/api/service/get/news`, {
      method: 'GET',
    });
    return await response.json();
  } catch (error) {
    console.error('Error fetching news:', error);
    return [];
  }
};

export const getProductsNoAuth = async (productID) => {

  // const accessToken = getTokens('access');

  if (productID !== undefined && productID !== null && productID !== '') {
    try {
      const response = await fetch(`${getBaseUrl()}/api/service/get/products?product_id=${productID}`, {
        method: 'GET',
      });
      return await response.json();
    } catch (error) {
      console.error('Error fetching products:', error);
      return [];
    }
  }
  try {
    const response = await fetch(`${getBaseUrl()}/api/service/get/products`, {
      method: 'GET',
    });
    return await response.json();
  } catch (error) {
    console.error('Error fetching products:', error);
    return [];
  }
}

export const getProductsDetailNoAuth = async (productID) => {
  try {
    const response = await fetch(`${getBaseUrl()}/api/service/get/products_detail?product_id=${productID}`, {
      method: 'GET',
    });
    return await response.json();
  } catch (error) {
    console.error('Error fetching products:', error);
    return [];
  }
}

export const getLogoNoAuth = async () => {
  try {
    const response = await fetch(`${getBaseUrl()}/api/service/get/logo`, {
      method: 'GET',
    });
    return await response.json();
  } catch (error) {
    console.error('Error fetching logo:', error);
    return null;
  }
}