import React, { useState, useEffect } from 'react';
import {
  Modal, ModalOverlay, ModalContent, ModalHeader,
  ModalCloseButton, ModalBody, ModalFooter,
  FormControl, FormLabel, Input, Textarea, Button
} from '@chakra-ui/react';
import { Form } from 'react-router-dom';

interface ModalFormProps {
  isOpen: boolean;
  onClose: () => void;
  modalType: 'product' | 'faq' | 'news' | 'contacts' | null;
  currentItem: any;
  onSave: (item: any) => void;
}

const ModalForm: React.FC<ModalFormProps> = ({ isOpen, onClose, modalType, currentItem, onSave }) => {
  const [localItem, setLocalItem] = useState(currentItem);

  useEffect(() => {
    console.log('Opening modal:', currentItem);
    setLocalItem(currentItem);
    if (modalType === 'news') {
      setLocalItem((prev: any) => ({ ...prev, date: new Date().toISOString().split('T')[0] }));
    }
  }, [currentItem]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    if (name.includes('features')) {
      const index = Number(name.split('-')[1]);
      setLocalItem((prev: any) => {
        const newFeatures = [...prev.features];
        newFeatures[index] = value;
        return { ...prev, features: newFeatures };
      });
      return;
    }
    if (name === 'photo') {
      const inputElement = e.target as HTMLInputElement;
      const file = inputElement.files?.[0];
      setLocalItem((prev: any) => ({ ...prev, photo: file }));
      return;
    }
    setLocalItem((prev: any) => ({ ...prev, [name]: value }));
  };

  const handleSave = () => {
    onSave(localItem);
  };

  console.log(`Rendering ${modalType}ModalForm:`, localItem);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {modalType === 'product' ? (localItem?.id ? 'Edit Product' : 'Add New Product') :
           modalType === 'faq' ? (localItem?.id ? 'Edit FAQ' : 'Add New FAQ') :
           modalType === 'news' ? (localItem?.id ? 'Edit News' : 'Add New News') :
           modalType === 'contacts' ? 'Contact Info' : ''} 
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {modalType === 'product' && (
            <>
              <FormControl>
                <FormLabel>Name</FormLabel>
                <Input name="name" value={localItem?.name || ''} onChange={handleInputChange} />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Description</FormLabel>
                <Textarea name="description" value={localItem?.description || ''} onChange={handleInputChange} />
              </FormControl>
              {localItem?.features.map((feature: string, index: number) => (
              <FormControl mt={4}>
                <FormLabel>Features {index+1}</FormLabel>
                <Input name={`features-${index}`} value={feature || ''} onChange={handleInputChange} />
              </FormControl>
              ))}
              <Button mt='2'onClick={()=>{
                setLocalItem((prev: any) => ({ ...prev, features: [...prev.features, ''] }));
              }}>Add Features</Button>
              <FormControl mt={4}>
                <FormLabel>Price</FormLabel>
                <Input type="number" name="price" value={localItem?.price || ''} onChange={handleInputChange} />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Image</FormLabel>
                <Input 
                  name="photo" 
                  type='file' 
                  accept=".png, .jpg, .jpeg, .webp"
                  onChange={handleInputChange}
                />
              </FormControl>
              
            </>
          )}
          {modalType === 'faq' && (
            <>
              <FormControl>
                <FormLabel>Question</FormLabel>
                <Input name="question" value={localItem?.question || ''} onChange={handleInputChange} />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Answer</FormLabel>
                <Textarea name="answer" value={localItem?.answer || ''} onChange={handleInputChange} />
              </FormControl>
            </>
          )}
          {modalType === 'news' && (
            <>
              <FormControl>
                <FormLabel>Title</FormLabel>
                <Input name="title" value={localItem?.title || ''} onChange={handleInputChange} />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Date</FormLabel>
                <Input 
                  type="date" 
                  name="date" 
                  value={localItem?.date || new Date().toISOString().split('T')[0]} 
                  onChange={handleInputChange} 
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Content</FormLabel>
                <Textarea name="content" value={localItem?.content || ''} onChange={handleInputChange} />
              </FormControl>
            </>
          )}
          {modalType === 'contacts' && (
            <>
                          {/* <Td>{contact.name}</Td>
              <Td>{contact.email}</Td>
              <Td>{contact.subject}</Td>
              <Td>{contact.message.substring(0, 50)}...</Td> */}
              <FormControl>
                <FormLabel>Phone</FormLabel>
                <Input name="phone" value={localItem?.name || ''} onChange={handleInputChange} readOnly={true} />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Email</FormLabel>
                <Input name="email" value={localItem?.email || ''} onChange={handleInputChange} readOnly={true} />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Address</FormLabel>
                <Textarea name="address" value={localItem?.message || ''} onChange={handleInputChange} readOnly={true} />
              </FormControl>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          {modalType !== 'contacts' && (
          <Button colorScheme="blue" mr={3} onClick={handleSave}>
            Save
          </Button>
          )}
          <Button onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalForm;